
import isArray from '../lodash/is_array';

/**
 *
 * @param {数组对象} arrObj
 * @param {*查找对象的key名} key
 * @param {*要查找对象的值} value
 * @return {删除指定对象，返回新数组对象}
 */
export default function  removeObjById(arrObj,key,value){
  if(isArray(arrObj)){
    var newArr = [];
    for(var i=0;i<arrObj.length;i++){
      if(arrObj[i][key] != value){
          newArr.push(arrObj[i])
        }
    }
  }else{
    return arrObj;
  }
  return newArr;
}
