import isWeixin from '../device/is_weixin';
import isIos from '../device/is_ios';
import isAndroid from '../device/is_android';
import setCookie from '../cookie/set_cookie';
import getCookie from '../cookie/get_cookie';
import deleteCookie from '../cookie/delete_cookie';
import constant from '../constant/constant';
import http from '../http/url';
import uuid from "../other/uuid";
import site_data from "./site_data";
import user_data from "./user_data";
import {windows} from "../sysenv/windowsimulate";
import toJson from "../json/to_json";
import storage from '../storage/storage'
/*
*系统数据
* */
let apiUrl = '';
let DomainUrl = '';
let heardsName_var = '';
let osData_var = '';
let uuId_var = '';
let session_token_var = '';
export default {
    getuuid() {
        let uuid = getCookie(constant.UUID.key) || uuId_var;
        if (uuid) {
            return uuid;
        } else {
            return this.setuuid();
        }
    },
    setuuid() {
        uuId_var = uuid();
        setCookie(constant.UUID.key, uuId_var, {
            domain: constant.UUID.site && http.gettopUrl()
        });
        setCookie(constant.UUID.key, uuId_var, {
            domain: constant.UUID.site && http.getupUrl()
        });
        return uuId_var
    },
    deluuid() {
        uuId_var = '';
        deleteCookie(constant.UUID.key)
    },
    getsession_token() {
        let session_token = getCookie(constant.session_token.key + site_data.getMuschId()) || session_token_var;
        if (session_token) {
            return session_token
        } else {
            return this.setsession_token()
        }
    },
    setsession_token(val) {
        let uuiddata = this.getuuid();
        setCookie(constant.session_token.key + site_data.getMuschId(), val || uuiddata, {
            domain: constant.session_token.site && http.gettopUrl()
        });
        setCookie(constant.session_token.key + site_data.getMuschId(), val || uuiddata, {
            domain: constant.session_token.site && http.getupUrl()
        });
        this.setHeards({
            [constant.session_token.key]: val || uuiddata
        });
        session_token_var = val || uuiddata;
        return val || uuiddata
    },
    delsession_token() {
        session_token_var = '';
        deleteCookie(constant.session_token.key + site_data.getMuschId())
    },
    getos() {
        let CLIENT_OSName = getCookie(constant.CLIENT_OSName.key) || osData_var;
        if (CLIENT_OSName) {
            return CLIENT_OSName;
        } else {
            return this.setos();
        }
    },
    setos() {
        let CLIENT_OS = constant.client_OS[0].key;
        if (isWeixin()) {
            CLIENT_OS = constant.client_OS[1].key;
        } else if (isIos()) {
            CLIENT_OS = constant.client_OS[2].key;
        } else if (isAndroid()) {
            CLIENT_OS = constant.client_OS[3].key
        }
        CLIENT_OS = constant.client_OS[0].key;
        setCookie(constant.CLIENT_OSName.key, CLIENT_OS, {
            domain: constant.CLIENT_OSName.site && http.gettopUrl(),
        });
        setCookie(constant.CLIENT_OSName.key, CLIENT_OS, {
            domain: constant.CLIENT_OSName.site && http.getupUrl(),
        });
        osData_var = CLIENT_OS;
        return CLIENT_OS;
    },
    delos() {
        deleteCookie(constant.CLIENT_OSName.key)
    },
    getHeards() {
        let headerName = getCookie(constant.headerName.key + site_data.getMuschId()) || storage({type: "local"}).getItem(constant.headerName.key) || heardsName_var;
        let header = {};
        if (headerName) {
            header = toJson(headerName);
            if (!header[constant.session_token.key]) {
                header[constant.session_token.key] = this.getsession_token()
            }
        } else {
            header = {
                [constant.CLIENT_OSName.key]: this.getos(),
                [constant.UUID.key]: this.getuuid(),
                [constant.session_token.key]: this.getsession_token()
            };
            this.setHeards(header)
        }
        return header;
    },
    setHeards(data) {
        let headerName = toJson(getCookie(constant.headerName.key + site_data.getMuschId()) || storage({type: "local"}).getItem(constant.headerName.key) || heardsName_var);
        headerName ? headerName = Object.assign(headerName, data) : headerName = data;
        heardsName_var = headerName;
        storage({type: "local"}).setItem(constant.headerName.key, JSON.stringify(headerName));
        setCookie(constant.headerName.key + site_data.getMuschId(), headerName, {
            domain: constant.headerName.site && http.gettopUrl(),
            path: '/'
        });
        setCookie(constant.headerName.key + site_data.getMuschId(), headerName, {
            domain: constant.headerName.site && http.getupUrl()
        })
    },
    delHeards() {
        heardsName_var = '';
        deleteCookie(constant.headerName.key + site_data.getMuschId())
        storage({type: 'local'}).removeItem(constant.headerName.key)
    },
    getformatting(data, nosite) {
        let formdata = {
            'header': this.getHeards(),
            'payload': data
        };
        formdata.header['REQ_TIME'] = +new Date() + '';
        if (!nosite && site_data.getsiteData()) {
            formdata.header['SITE_ID'] = site_data.getsiteData().siteId;
        }
        return JSON.stringify(formdata);
    },
    getapiUrl() {
        return getCookie(constant.apiUrl.key) || apiUrl;
    },
    setapiUrl(api) {
        setCookie(constant.apiUrl.key, api || http.gettopUrl(), {
            domain: constant.apiUrl.site && http.gettopUrl()
        });
        setCookie(constant.apiUrl.key, api || http.gettopUrl(), {
            domain: constant.apiUrl.site && http.getupUrl()
        });
        apiUrl = api || http.gettopUrl();
    },
    delapiUrl() {
        apiUrl = '';
        deleteCookie(constant.apiUrl.key)
    },
    setDomain: function (url) {
        storage({type: "local"}).setItem(constant.domain.key, url);
        DomainUrl = url;
    },
    getDomain: function () {
        return storage({type: "local"}).getItem(constant.domain.key) || DomainUrl;
    },
    getLiveApiUrl: function () {
        return getCookie(constant.liveAPIUrl.key) || windows[constant.liveAPIUrl.key];
    },
    setLiveAPIUrl: function (api) {
        setCookie(constant.liveAPIUrl.key, api || http.gettopUrl(), {
            domain: constant.liveAPIUrl.site && http.gettopUrl()
        });
        setCookie(constant.liveAPIUrl.key, api || http.gettopUrl(), {
            domain: constant.liveAPIUrl.site && http.getupUrl()
        });
        windows[constant.liveAPIUrl.key] = api || http.gettopUrl();
    },
    getPortApiUrl: function () {
        return getCookie(constant.portAPIUrl.key) || windows[constant.portAPIUrl.key];
    },
    setPortAPIUrl: function (api) {
        setCookie(constant.portAPIUrl.key, api || http.gettopUrl(), {
            domain: constant.portAPIUrl.site && http.gettopUrl()
        });
        setCookie(constant.portAPIUrl.key, api || http.gettopUrl(), {
            domain: constant.portAPIUrl.site && http.getupUrl()
        });
        windows[constant.portAPIUrl.key] = api || http.gettopUrl();
    },
    setTrackAPIUrl: function (api) {
        setCookie(constant.trackAPIUrl.key, api || http.gettopUrl(), {
            domain: constant.trackAPIUrl.site && http.gettopUrl()
        });
        setCookie(constant.trackAPIUrl.key, api || http.gettopUrl(), {
            domain: constant.trackAPIUrl.site && http.getupUrl()
        });
        windows[constant.trackAPIUrl.key] = api || http.gettopUrl();
    },
    getTrackAPIUrl: function (api) {
        return getCookie(constant.trackAPIUrl.key) || windows[constant.trackAPIUrl.key];
    },
    init(api) {
        if (api.domain) {
            setCookie(constant.siteData.key+'_key', constant.siteData.key + api.domain)
        }
        api.thisSite && setCookie('thisSite', api.thisSite);
        if (api.root) {
            api.domain && this.setDomain(api.domain.trim());
            api.api && this.setapiUrl(api.api.trim());
            api.liveAPI && this.setLiveAPIUrl(api.liveAPI.trim());
            api.portAPI && this.setPortAPIUrl(api.portAPI.trim());
            api.trackUrl && this.setTrackAPIUrl(api.trackUrl.trim());
            if (!api.isSite) {
                site_data.setsiteData(site_data.getsiteData, api.mcht);
            }
        } else {
            if (!this.getDomain()) {
                api.domain && this.setDomain(api.domain.trim());
                api.api && this.setapiUrl(api.api.trim());
                api.liveAPI && this.setLiveAPIUrl(api.liveAPI.trim());
                api.portAPI && this.setPortAPIUrl(api.portAPI.trim());
                api.trackUrl && this.setTrackAPIUrl(api.trackUrl.trim());
                if (!api.isSite) {
                    site_data.setsiteData('', api.mcht);
                }
            }
        }
        user_data.initUserData()
    },
    delbase_data() {
        this.delapiUrl();
        this.delsession_token();
        this.delHeards()
    }
}
