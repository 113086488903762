import setCookie from '../cookie/set_cookie';
import getCookie from '../cookie/get_cookie';
import deleteCookie from '../cookie/delete_cookie';
import http from '../http/url';
import constant from '../constant/constant';
import base_data from './base_data';
import site_data from "./site_data";
import storage from "../storage/storage";
import httpPost from "../http/http_posasynct";
import {windows} from "../sysenv/windowsimulate";


/*
*用户数据
* */

export default {
    getuserData() {
        return storage({type: "local"}).getItem(constant.userData.key) || getCookie(constant.userDataAdmin.key + site_data.getMuschId()) || getCookie(constant.userData.key + site_data.getMuschId()) ||"";
    },
    async setuserData(data) {
        if (data) {
            let userData = this.getuserData();
            if (typeof userData === 'object') {
                data = Object.assign(userData, data)
            }
            let baseData = {
                custId: data.custId,
                SESSION_TOKEN: data.SESSION_TOKEN
            };
            baseData = this.extendXlData(baseData, data); //扩展学历教育的补丁
            storage({type: "local"}).setItem(constant.userData.key, JSON.stringify(data));
            data.SESSION_TOKEN && base_data.setsession_token(data.SESSION_TOKEN);
            setCookie(constant.userData.key + site_data.getMuschId(), baseData, {
                domain: constant.userData.site && http.gettopUrl()
            });
            setCookie(constant.userData.key + site_data.getMuschId(), baseData, {
                domain: constant.userData.site && http.getupUrl()
            });
            setCookie(constant.userDataAdmin.key + site_data.getMuschId(), baseData, {
                domain: constant.userData.site && http.gettopUrl()
            });
            setCookie(constant.userDataAdmin.key + site_data.getMuschId(), baseData, {
                domain: constant.userData.site && http.getupUrl()
            });

            let requestUserData = await httpPost(base_data.getapiUrl() + '/base-service/api/cust/custInfo/queryById.do', base_data.getformatting({
                custId: data.custId
            }, true));
            if (requestUserData.success === '1') {
                storage({type: "local"}).setItem(constant.userData.key, JSON.stringify(Object.assign(data, requestUserData.data)));
            } else if (requestUserData.success === "005") {
                this.loginOut()
            }
        }
    },
    initUserData() {
        let userData = this.getuserData()
        if (userData.custId) {
            this.setuserData(userData)
        }
    },
    // 扩展学历教育
    extendXlData(baseData, data) {
        const {stuNo, facId, facType} = data;
        if (stuNo || facId || facType) {
            Object.assign(baseData, {stuNo, facId, facType})
        }
        return baseData
    },
    deluserData() {
        deleteCookie(constant.userData.key + site_data.getMuschId());
        deleteCookie(constant.userDataAdmin.key + site_data.getMuschId());
        storage({type: "local"}).removeItem(constant.userData.key)
    },
    loginSuccess(data) {
        // todo
        if (data && data.SESSION_TOKEN) {
            this.setuserData(data)
        } else {
            console.error("请传入登录对象")
        }
    },
    loginOut: function () {
        //    todo
        this.deluserData();
        setCookie('play_siteData', false);
        base_data.delsession_token();
        base_data.delHeards();
        site_data.logoutSetsiteData();
        delCookie();
        windows.loginObj && windows.loginObj.loginOut();
        function delCookie() {
            var keys = document.cookie.match(/[^ =;]+(?==)/g)
            if (keys) {
                for (var i = keys.length; i--;) {
                    document.cookie = keys[i] + '=0;path=/;expires=' + new Date(0).toUTCString()
                    document.cookie = keys[i] + '=0;path=/;domain=' + document.domain + ';expires=' + new Date(0).toUTCString()
                    document.cookie = keys[i] + '=0;path=/;domain=ratingdog.cn;expires=' + new Date(0).toUTCString()
                }
            }
        }
    },
    initSetLoginOut(func) {
        if (typeof func !== 'function') return console.error('传入方法');
        windows.loginObj = {};
        this.registerLoginOutFun(func);
        storage({type: "local"}).setItem('loginOutFun', func.toString());
    },
    setLoginOutFun() {
        let loginOutFun = storage({type: "local"}).getItem('loginOutFun');
        if(loginOutFun){
            this.initSetLoginOut(new Function(loginOutFun))
        }
    },
    registerLoginOutFun(func) {
        function registerFunction(context, name, func) {
            context[name] = func.bind(context);
        }

        registerFunction(windows.loginObj, "loginOut", func);
    }
}
