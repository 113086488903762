/**
 * getUrlParam / deleteUrlParam
 * From https://stackoverflow.com/questions/901115/how-can-i-get-query-string-values-in-javascript
 */
import {env,application} from '@uni/apis';

import {windows} from "../sysenv/windowsimulate";

const getUrlParam = (name, url) => {
    if (env.isWeChatMiniProgram) {
      return application.getCurrentPages()[0].query[name]
    } else {
        url ? url : url = windows.location.href
        name.replace(/[\[\]]/g, '\\$&');
        let regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
        let results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }
};

export default getUrlParam;
