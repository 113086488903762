import '@uni/apis';
import { windows } from './windowsimulate.js';
import ifnodeorbrowser from './ifnodeorbrowser.js';
import './is_typeof.js';
import toJson from './to_json.js';

var http_posasynct = {};

if (ifnodeorbrowser === 'node') {
  var http = require("http");

  http_posasynct = function (url, data, callback) {
    var err = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : console.error;
    var req = http.request({
      method: 'POST',
      path: url,
      timeout: 500000,
      headers: {
        "Content-Type": 'application/json'
      }
    }, function (res) {
      res.on('data', function (data) {
        callback(toJson(data));
      }).on('end', function () {});
    }).on('error', function (e) {
      err(e);
    });
    req.write(data);
    req.end();
  };
} else if (ifnodeorbrowser === 'WeChatMiniProgram') {
  http_posasynct = (url, data) => new Promise(function (resolve, reject) {
    wx.request({
      url: url,
      method: 'POST',
      data: data,
      header: {
        'content-type': 'application/json'
      },
      success: function (res) {
        resolve(toJson(res.data));
      },
      fail: function (err) {
        reject(err);
      }
    });
  });
} else {
  http_posasynct = function (url, data, callback) {
    var err = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : console.error;

    function sendPostRequest(url, data, callback) {
      var err = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : console.error;
      var request = new XMLHttpRequest();
      request.timeout = 500000;
      request.open('POST', url);
      request.setRequestHeader('Content-type', 'application/json; charset=utf-8');

      request.onload = () => {
        var responseText = toJson(request.responseText);
        windows.lastResult[onlyRequest] = responseText;
        callback(responseText);
      };

      request.onerror = function () {
        err(request);
      };

      request.send(data);
    }

    if (!windows.lastParams) windows.lastParams = {};
    if (!windows.lastResult) windows.lastResult = {};
    var params = toJson(data);
    var payloadParams = '';

    if (params.payload) {
      payloadParams = JSON.stringify(params.payload);
    }

    var onlyRequest = url + payloadParams;

    function handleRequest() {
      if (windows.lastParams[onlyRequest] === payloadParams) {
        console.log('命中缓存');
        callback(windows.lastResult[onlyRequest]);
        return windows.lastResult[onlyRequest];
      }

      windows.lastParams[onlyRequest] = payloadParams;
      sendPostRequest(url, data, callback, err = console.error);
      setTimeout(() => {
        windows.lastResult[onlyRequest] = '';
        windows.lastParams[onlyRequest] = '';
      }, 2000);
      return toJson(windows.lastResult[onlyRequest]);
    }

    if (payloadParams) {
      handleRequest();
    } else {
      sendPostRequest(url, data, callback, err = console.error);
    }
  };
}

var httpPost = http_posasynct;

export default httpPost;
