import { env } from '@uni/apis';

var thisEnv = '';

if (env.isNode) {
  thisEnv = 'node';
} else if (env.isWeChatMiniProgram) {
  thisEnv = 'WeChatMiniProgram';
} else {
  thisEnv = 'isWeb';
}

var ifnodeorbrowser = thisEnv;

export default ifnodeorbrowser;
