

import isArray from '../lodash/is_array';

/**
 * //截取数组对象指定数据，并返回新数组，可以往新数组添加数据 (数组长度不变)
 * arrObj: 数组对象
 * name：数组对象参数名
 * key：新增key
 * value：新增值
 */
export default function subArr(arrObj,name,key,value){
    if(isArray(arrObj)){
        var newArr = [];
        for(var i=0;i<arrObj.length;i++){
            newArr.push({
                [name]:arrObj[i][name],
                [key]:value
            })
        }
        return newArr;

    }else{
        return arrObj;
    }
}
