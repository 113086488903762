import {isZh} from './isZh';

function isAllNumber(num) {
    num = Number(num);
    if (typeof num === 'number') {
        var reg = /^\d+$/
        return reg.test(num);
    } else {
        return false;
    }
}

function isPhone(tel) {
    var reg = /^[1][0-9]{10}$/;
    return reg.test(tel);
}

function isCard(idcode) {
    // 加权因子
    var weight_factor = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2];
    // 校验码
    var check_code = ['1', '0', 'X', '9', '8', '7', '6', '5', '4', '3', '2'];
    var code = idcode + "";
    var last = idcode[17];
    var seventeen = code.substring(0, 17);
    var arr = seventeen.split("");
    var len = arr.length;
    var num = 0;
    for (var i = 0; i < len; i++) {
        num = num + arr[i] * weight_factor[i];
    }
    // 获取余数
    var resisue = num % 11;
    var last_no = check_code[resisue];
    var idcard_patter = /^[1-9][0-9]{5}([1][9][0-9]{2}|[2][0][0|1][0-9])([0][1-9]|[1][0|1|2])([0][1-9]|[1|2][0-9]|[3][0|1])[0-9]{3}([0-9]|[X])$/;
    var format = idcard_patter.test(idcode);
    return last === last_no && format ? true : false;
}

export default {
    isPhone,
    isZh,
    isCard,
    isAllNumber
}






