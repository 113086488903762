
// var isArray = require('./isArray');

import isArray from '../lodash/is_array';
/**
 * //数组对象全局替换并返回新数组对象
 * arr: 数组
 * a：被替换的字符串(旧的)
 * b：替换字符串(新的)
 */
export default function replaceArr(arr,a,b){
    if(isArray(arr)){

        var strArr =  JSON.stringify(arr);
        var newStrArr =  strArr.replace(new RegExp(a,'g'),b);
        return JSON.parse(newStrArr);
    }else{
        return arr;
    }
}

