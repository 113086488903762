const httpGet = (url, callback, err = console.error) => {
    const request = new XMLHttpRequest();
    request.timeout = 500000;
    request.open('GET', url);
    request.onload = () => callback(request.responseText);
    request.onerror = () => err(request);
    request.send();
};

export default httpGet;
