/**
 * 数组转对象
 * arrToObject([{ name: "张", age: 10, id: 'a1' }, { name: "李", age: 20, id: 'a2' } ], 'id')
 * // {a1: { name: "张", age: 10 }, a2: { name: "李", age: 20 }}
 */

import isArray from '../lodash/is_array';
import _ from "lodash";


export default function arrToObject(arr, key) {
    if (!isArray(arr)) {
        throw new Error('需要一个数组');
    }
    _.cloneDeep(arr).reduce((acc, cur) => {
        acc[cur[key]] = cur;
        delete cur[key];
        return acc;
    }, {});
}
