
import isArray from '../lodash/is_array';
import jia from '../string/jia';

export default function getLenById(arrObj,key,value){
    if(isArray(arrObj)){
        var num = 0;
        for(var i=0;i<arrObj.length;i++){
            if(arrObj[i][key]===value){
                num = jia(num,1);
            }
        }
        return num;

    }else{
        return arrObj;
    }
}
