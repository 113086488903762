import constant from '../constant/constant';
import base_data from './base_data';
import httpPost from "../http/http_posasynct";
import toJson from "../json/to_json";
import storage from "../storage/storage";

let SiteData_var = '';

/*
*站点数据
* */

export default {
    getsiteData() {
        console.log('constant.siteData.key',constant.siteData.key)
        let siteData = storage({type: "local"}).getItem(constant.siteData.key) || SiteData_var;
        if (siteData && siteData !== 'undefined') {
            return toJson(siteData);
        } else {
            this.setSite();
            return siteData;
        }
    },
    setSiteDataToId(data) {
        var siteData = storage({type: "local"}).getItem(constant.siteData.key);
        if (siteData && !(siteData === 'null')) {
            siteData = Object.assign(siteData, data)
        } else {
            siteData = data
        }
        storage({type: "local"}).setItem(constant.siteData.key, JSON.stringify(siteData))
        SiteData_var = siteData
    },
    getMuschId(key = base_data.getDomain()) {
        let val = storage({type: "local"}).getItem(storage({type: "local"}).getItem('muchKey'));
        if (val) {
            return val
        }
        return ''
    },
    setMuchId(id, key = base_data.getDomain()) {
        if (id) {
            storage({type: "local"}).setItem(key, id)
            storage({type: "local"}).setItem('muchKey', key)
        }
    },
    upSiteDate(data) {
        var siteData = storage({type: "local"}).getItem(constant.siteData.key);
        if (siteData && siteData !== 'undefined' && !(siteData === 'null') && data) {
            siteData = Object.assign(siteData, toJson(data))
            storage({type: "local"}).setItem(constant.siteData.key, JSON.stringify(siteData));
            SiteData_var = siteData
        } else {
            console.log('siteData不存在,不能更新')
        }
    },
    async setSite(fun) {
        let siteDomain = base_data.getDomain();
        if (!siteDomain) {
            return false
        }
        let setSiteData = await httpPost(base_data.getapiUrl() + '/base-service/common/ptsetSite/querySiteByDomain.do', base_data.getformatting({
            siteDomain: siteDomain
        }, true));
        if (setSiteData.success === '1') {
            this.setSiteDataToId(setSiteData.data);
            setTimeout(async () => {
                let saasSiteData = await httpPost(base_data.getapiUrl() + '/saastenant-service/common/ptsetSiteApp/queryBySiteDomain.do', base_data.getformatting({
                    siteDomain: siteDomain
                }));
                if (saasSiteData.success === '1') {
                    saasSiteData.data.newTime = +new Date();
                    this.setSiteDataToId(saasSiteData.data);
                    SiteData_var = saasSiteData.data;
                    this.setSiteId(saasSiteData.data.siteId)
                    setTimeout(function () {
                        fun && fun(true)
                    })
                } else {
                    fun && fun(false)
                }
            })
        } else {
            fun && fun(false)
        }
    },
    async setSiteId(id) {
        var siteData = storage({type: "local"}).getItem(constant.siteData.key);
        if (siteData && siteData !== 'undefined' && !(siteData === 'null')) {
            siteData = Object.assign(toJson(siteData), {
                siteId: id
            })
        } else {
            siteData = {
                siteId: id
            }
        }
        let setSiteData = await httpPost(base_data.getapiUrl() + '/base-service/api/ptsetSite/queryById.do', base_data.getformatting({
            siteId: id
        }));
        storage({type: "local"}).setItem(constant.siteData.key, JSON.stringify(setSiteData.data))
        SiteData_var = siteData
    },
    async setsiteData(fun, mcht) {
        if (storage({type: "local"}).getItem(constant.siteData.key)) {
            return false
        }
        let siteDomain = base_data.getDomain();
        if (!siteDomain) {
            return false
        }
        let setsiteData = await httpPost(base_data.getapiUrl() + '/base-service/login/anonLogin.do', base_data.getformatting({
            siteDomain: siteDomain
        }, true));
        if (setsiteData.success === '1') {
            if (mcht) {
                this.setMuchId(setsiteData.data.mchtId)
            }
            storage({type: "local"}).setItem(constant.siteData.key, JSON.stringify(setsiteData.data));
            base_data.setsession_token(setsiteData.data.SESSION_TOKEN);
            this.setSite();
            setTimeout(function () {
                fun && fun(true)
            })
        } else {
            fun && fun(false)
        }
    },
    async logoutSetsiteData() {
        // @todo
        let siteDomain = base_data.getDomain();
        if (!siteDomain) {
            return false
        }
        let setsiteData = await httpPost(base_data.getapiUrl() + '/base-service/login/anonLogin.do', base_data.getformatting({
            siteDomain: siteDomain
        }, true));
        if (setsiteData.success === '1') {
            storage({type: "local"}).setItem(constant.siteData.key, JSON.stringify(setsiteData.data));
            base_data.setsession_token(setsiteData.data.SESSION_TOKEN);
            this.setSite();
        }
    },
    delsiteData() {
        setCookie('play_siteData', false);
        window.localStorage.removeItem(constant.siteData.key)
    },
    getMobileDomain() {
        const siteData = toJson(localStorage.getItem(constant.siteData.key));
        if (typeof siteData === 'object') {
            let domian = siteData.h5SiteDomain || siteData.mobileSiteDomain;
            return location.protocol + '//' + domian;
        }
    },
    getPCDomain() {
        const siteData = toJson(localStorage.getItem(constant.siteData.key));
        if (typeof siteData === 'object') {
            let domian = siteData.pcSiteDomain;
            return location.protocol + '//' + domian;
        }
    }
}
