import siteData from "../base_data/site_data";
import userData from "../base_data/user_data";
import httpGetSynct from "../http/http_get_synct"
import setUrlParam from "../http/set_url_param";
import getUrlParam from "../http/get_url_param";
import deleteUrlParam from "../http/delete_url_param";
import setCookie from "../cookie/set_cookie";
import {windows} from "../sysenv/windowsimulate";
import deleteCookie from "../cookie/delete_cookie";
import getCookie from "../cookie/get_cookie";
import httpPostAsynct from "../http/http_posasynct";

var loginUrl = '/usercenter-service/security/login';
var ssoLogin = '/usercenter-service/login/oauth2/code/sso-login';
var isLoginUrl = '/auth/isLogin';
var logoutUrl = '/auth/logout';
var authUrl = '//' + windows.location.hostname;

function login(callbackFn, btnObj) {
    var redirectUrl = btnObj.redirectUrl || windows.location.href;
    var url = btnObj.loginUrl || authUrl + loginUrl;

    if (siteData.getsiteData().siteId) {
        url = setUrlParam('siteId', siteData.getsiteData().siteId, url);
    }

    url = setUrlParam('redirectUrl', encodeURI(redirectUrl), url);
    var gainCodeData = httpGetSynct(url);

    if (gainCodeData.success === 1) {
        userData.setuserData(gainCodeData.data);
        return gainCodeData;
    } else if (gainCodeData.success === 'security_login') {
        var gain_code_url = gainCodeData.gain_code_url;
        if (btnObj.type === "islogin") {
            loginHerf(gain_code_url);
        } else {
            setCookie('open_win_lenye', true);
            btnObj.openType ? windows.location.href = gain_code_url : loginOpen(gain_code_url, callbackFn)
        }
        return false;
    } else {
        console.error(gainCodeData.message);
        return false;
    }
}

function loginHerf(url) {
    windows.location.href = url;
}

function loginOpen(url, callbackFn) {
    var iTop = (windows.screen.availHeight - 30 - 1000) / 2; //获得窗口的垂直位置;

    var iLeft = (windows.screen.availWidth - 10 - 1000) / 2; //获得窗口的水平位置;

    var strWindowFeatures = "width=1000,height=1000,menubar=yes,location=yes,innerHeight=800resizable=yes,scrollbars=true,status=truetop=" + iTop + ",left=" + iLeft;
    openWin(url, "登录", strWindowFeatures)

    function openWin(url, text, winInfo) {
        // let winObj = windows.open('javascript:window.name;', '<script>location.replace("'+url+'")<\/script>',winInfo);
        var winObj = windows.open(url, text, winInfo);
        var loop = setInterval(function () {
            if (winObj.closed) {
                deleteCookie('open_win_lenye');
                callbackFn && callbackFn(getCookie('code_lenye'));
                deleteCookie('code_lenye');
                clearInterval(loop);
            }
        }, 10);
    }
}

function isLogin(callbackFn, Obj) {
    /*判断是否登录*/
    var authServierLogined = getUrlParam('authServierLogined', windows.location.href);

    if (authServierLogined) {
        authServierLogined === '1' && Obj.redirectUrl && login(callbackFn, Obj);
        return authServierLogined;
    } else {
        // let isLoginUrlData = getisLoginUrl(Obj);
        var isLoginUrlData = Obj.isLoginUrl || authUrl + isLoginUrl;
        var redirectUrl = Obj.redirectUrl || windows.location.href;
        var isLoginData = httpGetSynct(isLoginUrlData + '?redirectUrl=' + encodeURI(redirectUrl), '', true); // windows.location.href = isLoginUrlData + '?redirectUrl=' + (Obj.redirectUrl || windows.location.href);

        if (isLoginData && isLoginData.code === "1") {
            callbackFn && callbackFn(true);
            Obj.redirectUrl && login(callbackFn, Obj);
            return true;
        } else {
            return callbackFn && callbackFn(false);
        }
    }
}

function loginOut(Obj, callbackFn) {
    // let loginOutUrlData = getLoginOutUrl(Obj)
    var redirectUrl = Obj.redirectUrl || windows.location.href;
    var loginOutUrlData = Obj.logoutUrl || authUrl + logoutUrl;
    var loginOutData = httpGetSynct(loginOutUrlData + '?redirectUrl=' + encodeURI(redirectUrl), '', true);

    if (loginOutData) {
        return callbackFn && callbackFn(loginOutData);
    } else {
        return callbackFn && callbackFn(false);
    }
}


export default {
    init: function (callbackFn, Obj) {
        let code = getUrlParam('code', windows.location.href);
        let state = getUrlParam('state', windows.location.href);
        let authServierLogined = getUrlParam('authServierLogined', windows.location.href);
        if (code && state && !authServierLogined) {
            setCookie('code_lenye', {
                code: code,
                state: state
            });
            var code_lenye_last = getCookie('code_lenye_last');
            if (code_lenye_last && code_lenye_last.code && code_lenye_last.code === code && code_lenye_last.state === state) {
                var url = deleteUrlParam("code", windows.location.href);
                windows.location.href = deleteUrlParam("state", url);
            } else {
                var _url = callbackFn || authUrl + ssoLogin;

                if (siteData.getsiteData().siteId) {
                    _url = setUrlParam('siteId', siteData.getsiteData().siteId, _url);
                }

                _url = setUrlParam('code', encodeURI(code), _url);
                _url = setUrlParam('state', encodeURI(state), _url);
                var ssoLoginData = httpGetSynct(_url);
                userData.setuserData(ssoLoginData.data);
                setCookie('code_lenye_last', {
                    code: code,
                    state: state
                });
                (!Obj.openType)&&getCookie('open_win_lenye') && windows.close();
            }
            return {
                code: code,
                state: state
            }
        } else {
            if (Obj.type === 'login') {
                var _code_lenye_last = getCookie('code_lenye_last');

                if (_code_lenye_last && _code_lenye_last.code && _code_lenye_last.code === code && _code_lenye_last.state === state) {
                    var _url2 = deleteUrlParam("code", windows.location.href);
                    windows.location.href = deleteUrlParam("state", _url2);
                } else {
                    callbackFn && typeof callbackFn != "string" && login(callbackFn, Obj);
                }
            } else if (Obj.type === 'islogin') {
                isLogin(callbackFn, Obj);
            } else {
                console.error("type类型错误,值为islogin/login");
            }
        }
    },
    loginOut: loginOut
}
