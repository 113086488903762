import {windows} from "../sysenv/windowsimulate";
export default function isIphoneXmodel() {
  // X XS, XS Max, XR
  const xSeriesConfig = [
    {
      devicePixelRatio: 3,
      width: 375,
      height: 812
    },
    {
      devicePixelRatio: 3,
      width: 414,
      height: 896
    },
    {
      devicePixelRatio: 2,
      width: 414,
      height: 896
    }
  ];
  if (typeof windows !== 'undefined' && windows) {
    const { devicePixelRatio, screen } = windows;
    const { width, height } = screen;
    return xSeriesConfig.some(
      (item) =>
        item.devicePixelRatio === devicePixelRatio && item.width === width && item.height === height
    );
  }
  return false;
}
