
/**
 *
 * @param {日期 YYYY-MM-DD || YYYY-MM-DD hh:mm:ss} date
 * @param {* 数组格式天，小时，分钟，秒} callback
 */
export default function countdown(date,callback){
    var starttime = new Date(date);
    var timer =  setInterval(()=> {
        var nowtime = new Date();
        var time = starttime - nowtime;
        var day = parseInt(time / 1000 / 60 / 60 / 24);
        var hour = parseInt(time / 1000 / 60 / 60 % 24);
        var minute = parseInt(time / 1000 / 60 % 60);
        var seconds = parseInt(time / 1000 % 60);
        callback([day, hour , minute, seconds])
        if(day===0 && hour===0 && minute===0 && seconds===0){
            clearInterval(timer);
        }

    }, 1000);
}
