import setCookie from './set_cookie';
import {windows} from "../sysenv/windowsimulate";
import http from "../http/url";
import {env, storage} from '@uni/apis';

let deleteCookie = {};

if (env.isWeChatMiniProgram) {
    deleteCookie = (name) => storage.removeStorageSync({
        key: name
    });
} else {
    deleteCookie = (name) => {
        setCook(name, '', {
            hour: -1,
        });
        setCook(name, '', {
            hour: -1,
            domain: http.gettopUrl()
        });
        setCook(name, '', {
            hour: -1,
            domain: http.getupUrl()
        });
        setCook(name, '', {
            hour: -1,
            domain: http.getUrl()
        });
        setCookie(name, '', {
            hour: -1
        });

        function setCook(name, value, option) {
            let longTime = 10;
            let path = '; path=/';
            let val = encodeURIComponent(value);
            let cookie = encodeURIComponent(name) + '=' + val;
            if (option) {
                if (option.days) {
                    let date = new Date();
                    let ms = option.days * 24 * 3600 * 1000;
                    date.setTime(date.getTime() + ms);
                    cookie += '; expires=' + date.toGMTString();
                } else if (option.hour) {
                    let date = new Date();
                    let ms = option.hour * 3600 * 1000;
                    date.setTime(date.getTime() + ms);
                    cookie += '; expires=' + date.toGMTString();
                } else {
                    let date = new Date();
                    let ms = longTime * 365 * 24 * 3600 * 1000;
                    date.setTime(date.getTime() + ms);
                    cookie += '; expires=' + date.toGMTString();
                }
                if (option.path) {
                    cookie += '; path=' + option.path
                } else {
                    cookie += '; path=' + '/'
                }

                if (option.domain) {
                    cookie += '; domain=' + option.domain
                }
                if (option.secure) cookie += '; true';
            }
            windows.document.cookie = cookie;
        }

    };
}

export default deleteCookie;
