export function _iszh(s, bag){
    var i,c;
    for (i = 0; i < s.length; i++){
        c = s.charAt(i);
        if (bag.indexOf(c) > -1){
            return c;
        }
    }
    return "";
}
