
import  { isDate } from './isDate';

import {moment} from './moment';

export default function f24(date){
    if(isDate(date)){
       var ftxt = '';
       var hh =  Number(moment(date).format('HH'));
       if(hh >= 8 && hh < 11){
           ftxt = '上午' ;
       }else if(hh>= 11 && hh<13){
           ftxt = '中午';
       }else if(hh>=13 && hh<17){
           ftxt = '下午';
       }else if(hh>=17 && hh<19){
           ftxt = '傍晚';
       }else if(hh>=19 && hh<23){
           ftxt = '晚上';
       }else if(hh>=23 && hh<3){
           ftxt = '深夜';
       }else if(hh>=3 && hh<6){
           ftxt = '凌晨';
       }else if(hh>=6 && hh<8){
           ftxt = '早晨';
       }
       return ftxt;
    }else{
        return date;
    }
}
