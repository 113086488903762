import isObject from '../lodash/is_object';
/*
* 对象转数组
* * objectToArr({a1: { name: "张", age: 10 }, a2: { name: "李", age: 20 }}, 'id')
 * // [{ name: "张", age: 10, id: 'a1' }, { name: "李", age: 20, id: 'a2' } ]
* */
const objectToArr = (o, keyField) => (isObject(o) && Object.keys(o).reduce((acc, key) => {
    acc.push(Object.assign({}, {[keyField]: key}, o[key]));
    return acc;
}, []));

export default objectToArr;
