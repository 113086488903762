/**
 * setCookie / getCookie / deleteCookie
 * From https://stackoverflow.com/questions/1458724/how-do-i-set-unset-cookie-with-jquery/1458728#1458728
 */
import isObject from "../lodash/is_object";
import isCookie from "./is_cookie";
import {windows} from "../sysenv/windowsimulate";
import {env,storage} from '@uni/apis';
import windowStorage from '../storage/storage'
import constant from "../constant/constant";

let setCookie = {};
if (env.isNode) {
    // todo
    /* global global:true */
    if (!global['cookie']) {
        global['cookie'] = {}
    }
    setCookie = (name, value, option) => {
        global['cookie'][name] = value
    }
} else if (env.isWeChatMiniProgram) {
    setCookie = (name, value, option) => {
        storage.setStorageSync({
            key: name,
            data: {
                value: value
            }
        })
    }
} else {
    setCookie = (name, value, option) => {
        let is_cookie=isCookie();
        let longTime = 10;
        if (isObject(value)) {
            value = JSON.stringify(value);
        }
        if (!is_cookie) {
            windowStorage({type: "local"}).setItem(name, value)
        } else {
            let path = '; path=/';
            let val = encodeURIComponent(value);
            let cookie = encodeURIComponent(name) + '=' + val;
            if (option) {
                if (option.days) {
                    let date = new Date();
                    let ms = option.days * 24 * 3600 * 1000;
                    date.setTime(date.getTime() + ms);
                    cookie += '; expires=' + date.toGMTString();
                } else if (option.hour) {
                    let date = new Date();
                    let ms = option.hour * 3600 * 1000;
                    date.setTime(date.getTime() + ms);
                    cookie += '; expires=' + date.toGMTString();
                } else {
                    let date = new Date();
                    let ms = longTime * 365 * 24 * 3600 * 1000;
                    date.setTime(date.getTime() + ms);
                    cookie += '; expires=' + date.toGMTString();
                }
                if (option.path) {
                    cookie += '; path=' + option.path
                } else {
                    cookie += '; path=' + '/'
                }

                if (option.domain) {
                    cookie += '; domain=' + option.domain;
                }
                if (option.secure) cookie += '; true';
            }
            windows.document.cookie = cookie;
        }
    };
}

export default setCookie;
