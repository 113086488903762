import isArray from '../lodash/is_array';


/**
 *
 * @param {数组对象} arrObj
 * @param {*指定key} id
 * @param {*指定value} value
 */
export default function isArrById(arrObj,id,value){
    if(isArray(arrObj)){
        var isTrue = [];
        for(var i=0;i<arrObj.length;i++){
            if(arrObj[i][id] != value ){
                isTrue = false;
            }
        }
        return isTrue;

    }else{
        return arrObj;
    }
}
