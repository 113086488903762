import {windows} from "../sysenv/windowsimulate";

const isCookie = () => {
    let cookie = windows.document.cookie;
    if(!cookie){
        windows.document.cookie = '1';
    }
    return !!windows.document.cookie;
};

export default isCookie;
