

/**
 * 指定对象key，value 获取当前对象
 * @param {*} arrObj
 * @param {*} key
 * @param {*} value
 * var a = [{a:1,b:2},{a:11,b:22}] getObjByEle(a,'b',2) {a: 1, b: 2}
 */
export default function getObjByEle(arrObj,key,value){
    var obj = {};
    for(var i=0;i<arrObj.length;i++){
        if(arrObj[i][key]==value){
            obj = arrObj[i]
        }
    }
    return obj;
}
