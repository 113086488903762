import toJson from "../json/to_json";

const httpGet = (url, callback, err = console.error) => {
    const request = new XMLHttpRequest();
    request.open('GET', url,false);
    request.send();
    return toJson(request.responseText);
};

export default httpGet;
