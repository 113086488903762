



import isObject from '../lodash/is_object.js';

var storage;


function Storage(data) {
    this._k = data;
    this._s = localStorage;

}
storage = function (key) {
    return new Storage(key);
}

storage.fn = Storage.prototype = {
    get: function () {
        this._v = localStorage.getItem(this._k);
        return this;
    },
    set: function (values) {
        this._v = values;
        localStorage.setItem(this._k, this.toString().getValue());
        return this;
    },
    getValue() {
        return this._v;
    },
    remove: function () {
        localStorage.removeItem(this._k);
    },
    f: function () {
        return storage(this._tmp);
    },
    removeAll: function () {
        localStorage.clear();
    },
    add: function (obj) {

    },
    removeById: function () {

    },

    getKey: function () {
        var s = this._s;
        var arr = [];
        for (var i = 0; i < s.length; i++) {
            arr.push(s.key(i));
        }
        this._v = arr;
        return this;
    },
    toString: function () {
        if(isObject(this._v)){
            this._v = JSON.stringify(this._v);
        }
        return this;

    },
    toJson: function () {
        this._v = JSON.parse(this._v);
        return this;
    }
}






export default {
    storage,
}


