import isArray from '../lodash/is_array';

var moment,
    round = Math.round,
    shortcuts = 'Month|Date|Hours|Minutes|Seconds'.split('|');

function leftZeroFill(number, targetLength) {
    var output = number + '';
    while (output.length < targetLength) {
        output = '0' + output;
    }
    return output;
}

function dateAddRemove(date, _input, adding, val) {
    var isString = (typeof _input === 'string'),
        input = isString ? {} : _input,
        ms, M, currentDate;
    if (isString && val) {
        input[_input] = val;
    }
    ms = (input.ms || input.milliseconds || 0) +
        (input.s || input.seconds || 0) * 1e3 + // 1000
        (input.m || input.minutes || 0) * 6e4 + // 1000 * 60
        (input.h || input.hours || 0) * 36e5 + // 1000 * 60 * 60
        (input.d || input.days || 0) * 864e5 + // 1000 * 60 * 60 * 24
        (input.w || input.weeks || 0) * 6048e5; // 1000 * 60 * 60 * 24 * 7
    M = (input.M || input.months || 0) +
        (input.y || input.years || 0) * 12;
    if (ms) {
        date.setTime(+date + ms * adding);
    }
    if (M) {
        currentDate = date.getDate();
        date.setDate(1);
        date.setMonth(date.getMonth() + M * adding);
        date.setDate(Math.min(new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate(), currentDate));
    }
    return date;
}


// convert an array to a date.
// the array should mirror the parameters below
// note: all values past the year are optional and will default to the lowest possible value.
// [year, month, day , hour, minute, second, millisecond]
function dateFromArray(input) {
    return new Date(input[0], input[1] || 0, input[2] || 1, input[3] || 0, input[4] || 0, input[5] || 0, input[6] || 0);
}


function formatDate(date, inputString) {
    // console.log(date,"format....")
    var currentMonth = date.getMonth(),
        currentDate = date.getDate(),
        currentYear = date.getFullYear(),
        currentDay = date.getDay(),
        currentHours = date.getHours(),
        currentMinutes = date.getMinutes(),
        currentSeconds = date.getSeconds(),
        charactersToReplace = /(\\)?(Mo|MM?M?M?|Do|DDDo|DD?D?D?|dddd?|do?|w[o|w]?|YYYY|YY|a|A|hh?|HH?|mm?|ss?|zz?|LL?L?L?)/g,
        nonuppercaseLetters = /[^A-Z]/g,
        timezoneRegex = /\([A-Za-z ]+\)|:[0-9]{2} [A-Z]{3} /g,
        ordinal = moment.ordinal;
    // check if the character is a format
    // return formatted string or non string.

    function replaceFunction(input) {
        // create a couple variables to be used later inside one of the cases.
        var a, b;
        switch (input) {
            // MONTH
            case 'M' :
                return currentMonth + 1;
            case 'Mo' :
                return (currentMonth + 1) + ordinal(currentMonth + 1);
            case 'MM' :
                return leftZeroFill(currentMonth + 1, 2);
            case 'MMM' :
                return moment.monthsShort[currentMonth];
            case 'MMMM' :
                return moment.months[currentMonth];
            // DAY OF MONTH
            case 'D' :
                return currentDate;
            case 'Do' :
                return currentDate + ordinal(currentDate);
            case 'DD' :
                return leftZeroFill(currentDate, 2);
            // DAY OF YEAR
            case 'DDD' :
                a = new Date(currentYear, currentMonth, currentDate);
                b = new Date(currentYear, 0, 1);
                return ~~(((a - b) / 864e5) + 1.5);
            case 'DDDo' :
                a = replaceFunction('DDD');
                return a + ordinal(a);
            case 'DDDD' :
                return leftZeroFill(replaceFunction('DDD'), 3);
            // WEEKDAY
            case 'd' :
                return currentDay;
            case 'do' :
                return currentDay + ordinal(currentDay);
            case 'ddd' :
                return moment.weekdaysShort[currentDay];
            case 'dddd' :
                return moment.weekdays[currentDay];
            // WEEK OF YEAR
            case 'w' :
                a = new Date(currentYear, currentMonth, currentDate - currentDay + 5);
                b = new Date(a.getFullYear(), 0, 4);
                return ~~((a - b) / 864e5 / 7 + 1.5);
            case 'wo' :
                a = replaceFunction('w');
                return a + ordinal(a);
            case 'ww' :
                return leftZeroFill(replaceFunction('w'), 2);
            // YEAR
            case 'YY' :
                return currentYear % 100;
            case 'YYYY' :
                return currentYear;
            // AM / PM
            case 'a' :
                return currentHours > 11 ? 'pm' : 'am';
            case 'A' :
                return currentHours > 11 ? 'PM' : 'AM';
            // 24 HOUR
            case 'H' :
                return currentHours;
            case 'HH' :
                return leftZeroFill(currentHours, 2);
            // 12 HOUR
            case 'h' :
                return currentHours % 12 || 12;
            case 'hh' :
                return leftZeroFill(currentHours % 12 || 12, 2);
            // MINUTE
            case 'm' :
                return currentMinutes;
            case 'mm' :
                return leftZeroFill(currentMinutes, 2);
            // SECOND
            case 's' :
                return currentSeconds;
            case 'ss' :
                return leftZeroFill(currentSeconds, 2);
            // TIMEZONE
            case 'zz' :
            // depreciating 'zz' fall through to 'z'
            case 'z' :
                return (date.toString().match(timezoneRegex) || [''])[0].replace(nonuppercaseLetters, '');
            // LONG DATES
            case 'L' :
            case 'LL' :
            case 'LLL' :
            case 'LLLL' :
                return formatDate(date, moment.longDateFormat[input]);
            // DEFAULT
            default :
                return input.replace("\\", "");
        }
    }

    return inputString.replace(charactersToReplace, replaceFunction);
}

// date from string and format string
function makeDateFromStringAndFormat(string, format) {
    var inArray = [0],
        charactersToPutInArray = /[0-9a-zA-Z]+/g,
        inputParts = string.match(charactersToPutInArray),
        formatParts = format.match(charactersToPutInArray),
        i,
        isPm;

    // function to convert string input to date
    function addTime(format, input) {
        switch (format) {
            // MONTH
            case 'M' :
            // fall through to MM
            case 'MM' :
                inArray[1] = ~~input - 1;
                break;
            // DAY OF MONTH
            case 'D' :
            // fall through to DDDD
            case 'DD' :
            // fall through to DDDD
            case 'DDD' :
            // fall through to DDDD
            case 'DDDD' :
                inArray[2] = ~~input;
                break;
            // YEAR
            case 'YY' :
                input = ~~input;
                inArray[0] = input + (input > 70 ? 1900 : 2000);
                break;
            case 'YYYY' :
                inArray[0] = ~~input;
                break;
            // AM / PM
            case 'a' :
            // fall through to A
            case 'A' :
                isPm = (input.toLowerCase() === 'pm');
                break;
            // 24 HOUR
            case 'H' :
            // fall through to hh
            case 'HH' :
            // fall through to hh
            case 'h' :
            // fall through to hh
            case 'hh' :
                inArray[3] = ~~input;
                break;
            // MINUTE
            case 'm' :
            // fall through to mm
            case 'mm' :
                inArray[4] = ~~input;
                break;
            // SECOND
            case 's' :
            // fall through to ss
            case 'ss' :
                inArray[5] = ~~input;
                break;
        }
    }

    for (var i = 0; i < formatParts.length; i++) {
        addTime(formatParts[i], inputParts[i]);
    }
    // handle am pm
    if (isPm && inArray[3] < 12) {
        inArray[3] += 12;
    }
    return dateFromArray(inArray);
}

// compare two arrays, return the number of differences
function compareArrays(array1, array2) {
    var len = Math.min(array1.length, array2.length),
        lengthDiff = Math.abs(array1.length - array2.length),
        diffs = 0;

    for (var i = 0; i < len; i++) {
        if (~~array1[i] !== ~~array2[i]) {
            diffs++;
        }
    }
    return diffs + lengthDiff;
}

// date from string and array of format strings
function makeDateFromStringAndArray(string, formats) {
    var output,
        charactersToPutInArray = /[0-9a-zA-Z]+/g,
        inputParts = string.match(charactersToPutInArray),
        scores = [],
        scoreToBeat = 99,
        curDate,
        curScore;
    for (var i = 0; i < formats.length; i++) {
        curDate = makeDateFromStringAndFormat(string, formats[i]);
        curScore = compareArrays(inputParts, formatDate(curDate, formats[i]).match(charactersToPutInArray));
        if (curScore < scoreToBeat) {
            scoreToBeat = curScore;
            output = curDate;
        }
    }
    return output;
}

// Moment prototype object
function Moment(date) {
    if(typeof(date) == "string" &&  date.indexOf('-') != -1){
        date = date.replace(/-/g,'/');
    }
    this._d = date;
}

moment = function (input, format) {
    var date;
    // parse UnderscoreDate object
    if (input && input._d instanceof Date) {
        date = input._d;
        // parse string and format
    } else if (format) {
        if (isArray(format)) {
            date = makeDateFromStringAndArray(input, format);
        } else {
            date = makeDateFromStringAndFormat(input, format);
        }
        // parse everything else
    } else {
        date = input === undefined ? new Date() :
            input instanceof Date ? input :
                isArray(input) ? dateFromArray(input) :
                    new Date(input);
    }
    return new Moment(date);
};


// helper function for _date.from() and _date.fromNow()
function substituteTimeAgo(string, number) {
    return moment.relativeTime[string].replace(/%d/i, number || 1);
}

function relativeTime(milliseconds) {
    var seconds = Math.abs(milliseconds) / 1000,
        minutes = seconds / 60,
        hours = minutes / 60,
        days = hours / 24,
        years = days / 365;
    return seconds < 45 && substituteTimeAgo('s', round(seconds)) ||
        round(minutes) === 1 && substituteTimeAgo('m') ||
        minutes < 45 && substituteTimeAgo('mm', round(minutes)) ||
        round(hours) === 1 && substituteTimeAgo('h') ||
        hours < 22 && substituteTimeAgo('hh', round(hours)) ||
        round(days) === 1 && substituteTimeAgo('d') ||
        days <= 25 && substituteTimeAgo('dd', round(days)) ||
        days <= 45 && substituteTimeAgo('M') ||
        days < 345 && substituteTimeAgo('MM', round(days / 30)) ||
        round(years) === 1 && substituteTimeAgo('y') ||
        substituteTimeAgo('yy', round(years));
}

// shortcut for prototype
moment.fn = Moment.prototype = {

    valueOf: function () {
        return +this._d;
    },

    native: function () {
        return this._d;
    },

    format: function (inputString) {
        return formatDate(this._d, inputString);
    },

    add: function (input, val) {
        this._d = dateAddRemove(this._d, input, 1, val);
        return this;
    },

    subtract: function (input, val) {
        this._d = dateAddRemove(this._d, input, -1, val);
        return this;
    },
    diff: function (input, val, float) {
        var inputMoment = moment(input),
            diff = this._d - inputMoment._d,
            year = this.year() - inputMoment.year(),
            month = this.month() - inputMoment.month(),
            day = this.day() - inputMoment.day(),
            output;
        if (val === 'months') {
            output = year * 12 + month + day / 30;
        } else if (val === 'years') {
            output = year + month / 12;
        } else {
            output = val === 'seconds' ? diff / 1e3 : // 1000
                val === 'minutes' ? diff / 6e4 : // 1000 * 60
                    val === 'hours' ? diff / 36e5 : // 1000 * 60 * 60
                        val === 'days' ? diff / 864e5 : // 1000 * 60 * 60 * 24
                            val === 'weeks' ? diff / 6048e5 : // 1000 * 60 * 60 * 24 * 7
                                val === 'days' ? diff / 3600 : diff;
        }
        return float ? output : round(output);
    },

    from: function (time, withoutSuffix) {
        var difference = this.diff(time),
            rel = moment.relativeTime,
            output = relativeTime(difference);
        return withoutSuffix ? output : (difference < 0 ? rel.past : rel.future).replace(/%s/i, output);
    },

    fromNow: function (withoutSuffix) {
        return this.from(moment(), withoutSuffix);
    },

    isLeapYear: function () {
        var year = this._d.getFullYear();
        return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
    }
};

// helper for adding shortcuts
function makeShortcut(name, key) {
    moment.fn[name] = function (input) {
        if (input != null) {
            this._d['set' + key](input);
            return this;
        } else {
            return this._d['get' + key]();
        }
    };
}

// loop through and add shortcuts
for (var i = 0; i < shortcuts.length; i++) {
    makeShortcut(shortcuts[i].toLowerCase(), shortcuts[i]);
}

// add shortcut for year (uses different syntax than the getter/setter 'year' == 'FullYear')
makeShortcut('year', 'FullYear');

// add shortcut for day (no setter)
moment.fn.day = function () {
    return this._d.getDay();
};


export {
    moment
}
