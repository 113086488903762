// 低代码常用方法，学历继续教育用
import {moment} from '../times/moment';
import isPC from "../device/is_pc";
import base_data from "../base_data/base_data";
import toJson from "../json/to_json";
import storage from "../storage/storage";
import httpPost from "../../dist_mod/es/http_post";
import user_data from "../base_data/user_data";

export default {
    // 上传格式化，pc，移动端通用
    uploadFormatter(params) {
        let isPc = isPC();
        const res = isPc ? params : params.data;
        if (res.success != 1) return alert(`上传失败，原因：${res.message}`);
        let imgURL //封面图片，移动端根据文件名称生成一个
        if (!isPc) imgURL = res.data.fileType == 'image' ? res.data.fileUrl : this.textToImage(res.data.fileName);
        return {
            success: true,
            url: res.data.fileUrl,
            downloadURL: res.data.fileUrl,
            imgURL,
            ...res.data
        }
    },
    // 上传组件 上传前拦截请求头,校验大小，修改头部文件类型。移动端略有不同
    beforeUpload(files, config) {
        if (!isPC()) files = files.file;
        // 上传大小，前端校验
        let {name, size} = files;
        size = Number(size) / 1024 / 1024;
        let limitSize = Number(config.headers.maxSize);
        if (size > limitSize) {
            alert(`上传失败，文件过大，最大限制${limitSize}M`)
            return false
        }

        // 根据上传文件类型，修改请求头部类型字段
        let ext = name.substring(name.lastIndexOf(".") + 1);
        const roles = {
            bin: 'stream',
            dms: 'stream',
            lha: 'stream',
            lzh: 'stream',
            exe: 'stream',
            so: 'stream',
            dll: 'stream',
            zip: 'stream',
            gzip: 'stream',
            rar: 'stream',
            tar: 'stream',
            au: 'audio',
            snd: 'audio',
            mid: 'audio',
            midi: 'audio',
            kar: 'audio',
            mpga: 'audio',
            mp2: 'audio',
            mp3: 'audio',
            aac: 'audio',
            wav: 'audio',
            pcm: 'audio',
            bmp: 'image',
            gif: 'image',
            ief: 'image',
            jpeg: 'image',
            jpg: 'image',
            jpe: 'image',
            png: 'image',
            tiff: 'image',
            tif: 'image',
            doc: 'doc',
            xlsx: 'doc',
            xls: 'doc',
            docx: 'doc',
            pdf: 'doc',
            dbf: 'doc',
            ppt: 'doc',
            css: 'text',
            html: 'text',
            htm: 'text',
            asc: 'text',
            txt: 'text',
            rtx: 'text',
            rtf: 'text',
            sgml: 'text',
            sgm: 'text',
            tsv: 'text',
            wml: 'text',
            wmls: 'text',
            etx: 'text',
            xsl: 'text',
            xml: 'text',
            bpmn: 'text',
            mpeg: 'video',
            mpg: 'video',
            mpe: 'video',
            qt: 'video',
            mov: 'video',
            mxu: 'video',
            avi: 'video',
            movie: 'video',
            mp4: 'video',
        }
        if (!(ext in roles)) {
            alert(`不支持的文件格式`)
            return false
        }
        let fileType = roles[ext];
        config.headers && (config.headers.fileType = fileType);
        if (!isPC()) return config;
    },
    // 文本转图片，上传附件时封面回显
    textToImage(text) {
        let tLength = text.length;
        let canvas = document.createElement("canvas");
        let context = canvas.getContext("2d");
        canvas.width = 150;
        canvas.height = canvas.width;
        context.textAlign = "center";
        context.fillStyle = "#fff";
        context.fillRect(0, 0, canvas.width, canvas.height);
        context.font = "25px Arial";
        context.fillStyle = "#000";
        // context.fillText(text, canvas.width / 2, canvas.height / 2);
        this.canvasTextAutoLine(text, canvas, context, canvas.width / 2, canvas.height / 2);
        return canvas.toDataURL("image/jpeg")
    },
    canvasTextAutoLine(text, canvas, context, x, y, maxWidth, lineHeight) {
        if (typeof text != 'string' || typeof x != 'number' || typeof y != 'number') {
            return;
        }
        if (typeof maxWidth == 'undefined') {
            maxWidth = (canvas && canvas.width) || 150;
        }
        if (typeof lineHeight == 'undefined') {
            lineHeight = 25;
        }

        // 字符分隔为数组
        var arrText = text.split('');
        var line = '';

        for (var n = 0; n < arrText.length; n++) {
            var testLine = line + arrText[n];
            var metrics = context.measureText(testLine);
            var testWidth = metrics.width;
            if (testWidth > maxWidth && n > 0) {
                context.fillText(line, x, y);
                line = arrText[n];
                y += lineHeight;
            } else {
                line = testLine;
            }
        }
        context.fillText(line, x, y);
    },
    // 获取上传文件
    getUploadFiles(list) {
        if (!list) return []
        if (!Array.isArray(list)) return list.split(',');
        const arr = [];
        list.forEach((item) => {
            if (item.fileURL) {
                arr.push({
                    fileUrl: item.fileURL || item.fileUrl,
                    fileName: item.name || item.item.fileName || '',
                    fileType: item.fileType,
                    fileId: item.fileId,
                    fileSize: item.fileSize || 0
                })
            } else {
                const obj = item.response.data || item.response;
                obj.imgURL && delete obj.imgURL
                arr.push(item.response.data || item.response)
            }
        })
        return arr;
    },
    // 回显上传文件方法
    setUploadFile(path, name) {
        let fileName = name || path
        const formatData = [];
        if (Array.isArray(path)) {
            // 如果是数组
            path.forEach((item) => {
                let imgURL = item.fileType == 'image' ? item.fileUrl : this.textToImage(item.fileName)
                formatData.push({
                    name: item.fileName,
                    state: "done",
                    size: item.fileSize || 1024,
                    downloadURL: item.fileUrl,
                    fileURL: item.fileUrl,
                    imgURL: imgURL,
                    fileId: item.fileId,
                    fileType: item.fileType,
                    fileName: item.fileName,
                    fileUrl: item.fileUrl,
                    url: item.fileUrl
                })
            })
        } else {
            // 如果只是文件路径
            formatData.push({
                name: fileName,
                state: "done",
                // size: 1024,
                downloadURL: path,
                fileURL: path,
                imgURL: path,
                fileUrl: path,
                fileName: name
            })
        }
        return formatData
    },
    // rax下载器预览
    onPreview(event) {
        let url = event.url || event.downloadURL;
        this.downloadPassOriginLimit(url)
    },
    // 下载文件方法
    downLoadFile(url, filename) {
        let a = document.createElement('a');
        a.style = 'display: none'; // 创建一个隐藏的a标签
        a.download = filename;
        a.href = url;
        document.body.appendChild(a);
        a.click(); // 触发a标签的click事件
        document.body.removeChild(a);
    },
    downloadPassOriginLimit(url, name) {
        name = name || url;
        const x = new window.XMLHttpRequest();
        x.open('GET', url, true);
        x.responseType = 'blob';
        x.onload = () => {
            const href = window.URL.createObjectURL(x.response);
            const a = document.createElement('a');
            a.href = href;
            a.download = name;
            a.click();
        };
        x.send();
    },
    timeFormat(timestamp, {isDate, isMonth, isTime} = {}) {
        if (!timestamp) return ''
        let date = new Date(timestamp);
        if (!date) return ''
        let result = moment(date).format("YYYY-MM-DD HH:mm:ss");
        if (isDate) result = moment(date).format("YYYY-MM-DD");
        if (isMonth) result = moment(date).format("YYYY-MM");
        if (isTime) result = moment(date).format("HH:mm:ss");
        return result
    },
    // 获取时间范围选择器时间
    getAreaTimePickerTime(time) {
        if (!time) return ['', ''];
        let startTime = '';
        let endTime = '';
        if (typeof time[0] === 'object') {
            startTime = this.timeFormat(time[0]._d);
            endTime = this.timeFormat(time[1]._d);
        } else if (typeof time[0] === 'string') {
            startTime = time[0];
            endTime = time[1];
        }
        return [startTime, endTime]
    },
    // 回显时间范围选择器
    setAreaTimePickerTime(s, e) {
        if (!s || !e) return []
        return [s, e]
    },
    deleteUploadFile(obj) {
        const response = obj.response || obj.data.response;
        const {fileId} = response;
        if (!fileId) return;
        const formData = new FormData();
        formData.append('fileId', fileId);
        const request = new XMLHttpRequest();
        request.timeout = 500000;
        request.open('POST', base_data.getapiUrl() + '/file-service/deleteFile.do');
        request.onload = () => {
            console.log(toJson(request.responseText))
        };
        request.onerror = () => {
            console.log(request)
        };
        request.send(formData);
    },
    // 学历教育设置站点logo插件
    setSiteLogo(obj, domain=location.hostname) {
        //默认是亳州站点logo
        let siteLogoInfo = {
            whiteFontLogo: 'http://juheapi.bztvu.ah.cn/group1/M00/00/00/Cg0lJGSn3FSAFhkYAAAutlxRtEI359.png',
            colorFontLogo: 'http://juheapi.bztvu.ah.cn/group1/M00/00/00/Cg0lJGSn1-OAJqzSAABCNGMsCZg680.png',
            siteName: '亳州开放大学',
            identiySpaceLogo: 'https://www.ahlnjy.cn/group1/M00/00/B5/wKgBpGOFuEGAFmrqAACIFOnsOH4883.png',
            singleLogo: 'http://juheapi.bztvu.ah.cn/group1/M00/00/00/Cg0lJGSdJaeAQk5OAAA-LoKynyQ001.png'
        }
        try {
            httpPost(base_data.getapiUrl() + '/openeduadmin-service/common/oeaSiteLog/queryList.do', base_data.getformatting({domain}), (res) => {
                if (res.success == 1 && res.data.length > 0) {
                    siteLogoInfo = res.data[0];
                    obj.setState({
                        siteLogoInfo: res.data[0]
                    })
                } else {
                    obj.setState({
                        siteLogoInfo: siteLogoInfo
                    })
                }
                storage({type:'local'}).setItem('siteLogoInfo',JSON.parse(siteLogoInfo));
            })
        } catch (e) {
            obj.setState({
                siteLogoInfo: siteLogoInfo
            })
        }
    },
    getYearBatchParams(params = {}) {
        const filed = ['year', 'batch', 'schoolYear', 'batch', 'openYear', 'openBatch'];
        if (!params.yearBatch) {
            filed.forEach(item => {
                delete params[item]
            })
            return params
        }
        const yearBatchArr = params.yearBatch.split(',');
        params.year = yearBatchArr[0];
        params.schoolYear = yearBatchArr[0];
        params.openYear = yearBatchArr[0];
        params.batch = yearBatchArr[1];
        params.openBatch = yearBatchArr[1];
        return params
    },
    constant: {
        zskf: {
            bztvu: 'https://chatbot.aliyuncs.com/intl/index.htm?locale=zh-CN&from=stb3xwJPAQ',
            learnyun: 'https://chatbot.aliyuncs.com/intl/index.htm?locale=zh-CN&from=jSOjPMxV3E'
        },
        znjf: {
            bztvu: 'https://chatbot.aliyuncs.com/intl/index.htm?locale=zh-CN&from=17hcyKz9mm',
            learnyun: 'https://chatbot.aliyuncs.com/intl/index.htm?locale=zh-CN&from=zq1nQHLxRB'
        }
    },
    initYearBatchList(url=`/openeduadmin-service/manage/oeaYearBatch/getYearBatch.do`) {
        if (this.getYearBatchList()) return Promise.resolve(this.getYearBatchList());
        return new Promise((resolve, reject)=>{
            httpPost(base_data.getapiUrl() +  url, base_data.getformatting({}), (res) => {
                if (res.success == 1) {
                    const yearBatchData = res.data;
                    storage({type: 'local'}).setItem('openYearBatch', JSON.stringify(yearBatchData));
                    storage({type: 'session'}).setItem('openYearBatch', JSON.stringify(yearBatchData));
                    const currentYearBatch = {
                        year: yearBatchData.year,
                        batch: yearBatchData.batch, yearBatch: yearBatchData.yearBatch,
                        yearBatchValue: yearBatchData.yearBatchValue
                    };
                    storage({type: 'local'}).setItem('currentYearBatch', JSON.stringify(currentYearBatch));
                    storage({type: 'session'}).setItem('currentYearBatch', JSON.stringify(currentYearBatch));
                    const yearBatchList = yearBatchData.yearBatchList.map((item) => {
                        item.label = item.yearBatch;
                        item.value = item.yearBatchValue;
                        return item
                    });
                    storage({type: 'local'}).setItem('yearBatchList', JSON.stringify(yearBatchList));
                    storage({type: 'session'}).setItem('yearBatchList', JSON.stringify(yearBatchList));
                    resolve(yearBatchData)
                }else {
                    reject(new Error(res.message));
                }
            })
        })
    },
    getYearBatchList() {
        return storage({type: 'local'}).getItem('yearBatchList') || storage({type: 'session'}).getItem('yearBatchList');
    },
    getCurrentYearBatch() {
        return storage({type: 'local'}).getItem('currentYearBatch') || storage({type: 'session'}).getItem('currentYearBatch');
    },
    initStudentInfo(data = user_data.getuserData()) {
        if (this.getStudentInfo() || data.facId || !data.stuNo || !data.custId) return Promise.resolve(this.getStudentInfo());
        return new Promise((resolve, reject)=>{
            httpPost(base_data.getapiUrl() + '/openeduadmin-service/api/oeaStudent/queryByCustId.do', base_data.getformatting({ custId : data.custId}), (res) => {
                if (res.success == 1) {
                    storage({type: 'local'}).setItem('studentInfo', JSON.stringify(res.data));
                }else {
                    reject(new Error(res.message));
                }
            })
        })
    },
    getStudentInfo() {
        return storage({type: 'local'}).getItem('studentInfo')
    }
}




