/*
*常量
* */
import getCookie from "../cookie/get_cookie";

const userData = {
    key: 'userData_lenye',
    name: '用户存储数据',
    site: !getCookie('thisSite')
};
const userDataAdmin = {
    key: 'userData_lenye_admin',
    name: '用户存储数据',
    site: !getCookie('thisSite')
};
const headerName = {
    key: 'header_name_lenye',
    name: '请求头',
    site: !getCookie('thisSite')
};
const siteData = {
    key: getCookie('siteData_lenye_key') || 'siteData_lenye',
    name: '站点数据',
    site: false
};

const UUID = {
    key: 'UUID',
    name: '设备号',
    site: true
};
const CLIENT_OSName = {
    key: 'CLIENT_OS',
    name: '系统Name',
    site: true
};
const client_OS = [
    {
        key: 'O',
        name: '其他系统'
    },
    {
        key: 'WA',
        name: '微信,微信小程序'
    },
    {
        key: 'IOS',
        name: '苹果系统'
    },
    {
        key: 'A',
        name: '安卓'
    }
];
const session_token = {
    key: 'SESSION_TOKEN',
    name: '登录token',
    site: !getCookie('thisSite')
};

export default {
    UUID: UUID,
    userData: userData,
    userDataAdmin: userDataAdmin,
    client_OS: client_OS,
    session_token: session_token,
    CLIENT_OSName: CLIENT_OSName,
    headerName: headerName,
    siteData: siteData,
    thirdData: {
        key: 'thirdData_lenye',
        name: '第三方登录参数',
        site: true
    },
    apiUrl: {
        key: 'apiUrl_base_lenye',
        name: '接口地址',
        site: !getCookie('thisSite')
    },
    liveAPIUrl: {
        key: 'liveAPIUrl_base_lenye',
        name: '直播接口地址',
        site: true
    },
    portAPIUrl: {
        key: 'portAPIUrl_base_lenye',
        name: '第三方接口地址',
        site: true
    },
    trackAPIUrl: {
        key: 'trackAPIUrl_base_lenye',
        name: '大数据接口地址',
        site: true
    },
    getsiteUrl: {
        key: '/base-service/login/anonLogin.do',
        name: '获取站点信息接口'
    },
    getsite: {
        key: '/base-service/common/ptsetSite/querySiteByDomain.do',
        name: '获取站点信息接口'
    },
    getuserUrl: {
        key: '/base-service/api/cust/userSelf/detail.do',
        name: '用户信息'
    },
    gettiemUrl: {
        key: '/base-service/common/sysdate.do',
        name: '获取系统时间'
    },
    time: {
        key: 'time_sys_base_lenye',
        name: '系统时间'
    },
    domain: {
        key: 'domain_base_lenye',
        name: '域名',
        site: false
    }
}
