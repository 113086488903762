import {_iszh} from './_isZh';
import trim from './trim';

export function isZh(s) {
    s = trim(s);
    var errorchar;
    var badchar = "abcdefghijklmnopqrstuvwxyzabcdefghijklmnopqrstuvwxyz0123456789><,[]{}?/+=|//:;~!#$%()`";
    errorchar = _iszh(s, badchar)
    if (errorchar != "") {
        return false;
    }
    return true;
}
