
import jia from '../string/jia';

export default function total(arr){
    var totals = 0;
    for(var i=0;i<arr.length;i++){
        totals = jia(totals,Number(arr[i]));
    }
    return totals;
}

// module.exports = total;
