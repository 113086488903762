import {env} from '@uni/apis';
let thisEnv='';
if(env.isNode){
    thisEnv='node'
}else if(env.isWeChatMiniProgram){
    thisEnv='WeChatMiniProgram'
}else {
    thisEnv='isWeb'
}
export default thisEnv;
