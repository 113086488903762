import toJson from "../json/to_json";
import {env, storage} from '@uni/apis';
import {windows} from "../sysenv/windowsimulate";
import isCookie from "./is_cookie";
import windowStorage from '../storage/storage'

let getCookie = {};
if (env.isNode) {
    //todo
    if (!global['cookie']) {
        global['cookie'] = {}
    }
    getCookie = (name) => {
        return global['cookie'][name]
    };
} else if (env.isWeChatMiniProgram) {
    getCookie = (name, value, option) => {
        let data=storage.getStorageSync({
            key: name
        })
        return data?data.value:''
    }
} else {
    getCookie = (name) => {
        let is_cookie=isCookie();
        if (!is_cookie) {
            return toJson(windowStorage({type: "local"}).getItem(name));
        } else {
            let nameEQ = encodeURIComponent(name) + '=';
            let ca = windows.document.cookie.split(';');
            for (let i = 0; i < ca.length; i++) {
                let c = ca[i];
                while (c.charAt(0) === ' ') c = c.substring(1, c.length);
                if (c.indexOf(nameEQ) === 0) {
                    let getData = decodeURIComponent(c.substring(nameEQ.length, c.length));
                    if (getData && getData !== 'undefined' && getData !== 'null') {
                        return toJson(getData)
                    } else {
                        return ''
                    }
                }
            }
            return null;
        }
    };
}

export default getCookie;
