import toJson from "../json/to_json";
import ifnodeorbrowser from "../sysenv/ifnodeorbrowser";
import {windows} from "../sysenv/windowsimulate";
// eslint-disable-next-line max-params
let httpPostAsynct = {};
if (ifnodeorbrowser === 'node') {
    var http = require("http");
    httpPostAsynct = (url, data, callback, err = console.error) => {
        var req = http.request({
            method: 'POST',
            path: url,
            timeout: 500000,
            headers: {
                "Content-Type": 'application/json',
            }
        }, function (res) {
            res.on('data', function (data) {
                callback(toJson(data))
            }).on('end', function () {
            });
        }).on('error', function (e) {
            err(e)
        });
        req.write(data);
        req.end();
    }
} else if (ifnodeorbrowser === 'WeChatMiniProgram') {
    httpPostAsynct = (url, data) => new Promise(function (resolve, reject) {
        wx.request({
            url: url,
            method: 'POST',
            data: data,
            header: {
                'content-type': 'application/json'
            },
            success(res) {
                resolve(toJson(res.data))
            },
            fail(err) {
                reject(err)
            }
        })
    })
} else {
    httpPostAsynct = (url, data, cross, err = console.error) => {
        let params = toJson(data);
        let payloadParams = '';
        let onlyRequest = '';
        if (params.payload) {
            if (!windows.lastParams) windows.lastParams = {};
            if (!windows.lastResult) windows.lastResult = {};
            payloadParams = JSON.stringify(params.payload)
            onlyRequest = url + payloadParams;
        }

        function sendPostRequest() {
            var request = new XMLHttpRequest();
            request.open('POST', url, false);
            request.setRequestHeader('Content-type', 'application/json; charset=utf-8');
            if (cross) {
                request.setRequestHeader('X-Requested-With', 'XMLHttpRequest true');
                request.withCredentials = true;
            }
            request.send(data);
            return toJson(request.responseText);
        }

        function handleRequest(params) {
            if (windows.lastParams[onlyRequest] === payloadParams) {
                console.log('命中缓存');
                return windows.lastResult[onlyRequest];
            }
            windows.lastParams[onlyRequest] = payloadParams;
            windows.lastResult[onlyRequest] = sendPostRequest();
            setTimeout(() => {
                windows.lastResult[onlyRequest] = '';
                windows.lastParams[onlyRequest] = '';
            }, 2000);
            return windows.lastResult[onlyRequest];
        }

        if (payloadParams) {
            return handleRequest(data)
        } else {
            return sendPostRequest()
        }
        /* const request = new XMLHttpRequest();
         request.open('POST', url, false);
         request.setRequestHeader('Content-type', 'application/json; charset=utf-8');
         if (cross) {
             request.setRequestHeader('X-Requested-With', 'XMLHttpRequest true');
             request.withCredentials = true;
         }
         request.send(data);
         return toJson(request.responseText);*/
    };
}

export default httpPostAsynct;
