import {windows} from "../sysenv/windowsimulate";
import ifnodeorbrowser from "../sysenv/ifnodeorbrowser";

export default function ua(lower) {
  if (ifnodeorbrowser !== 'node') {
    return lower ? windows.navigator.userAgent.toLowerCase() : windows.navigator.userAgent;
  }else {
    return 'node'
  }
}
