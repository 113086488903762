import isArray from '../lodash/is_array';

export default function subArrToStr(arrObj,id){
    if(isArray(arrObj)){
        var newArr = [];
        for(var i=0;i<arrObj.length;i++){
            newArr.push(arrObj[i][id])
        }
        return newArr.toString();

    }else{
        return arrObj;
    }
}
