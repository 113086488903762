/**
 * @ en
 * lenye_base.formatDate(2021/01/12,MMMM-dd-hh);
 */
const formatDate = (date, format) => {
    if (!date) return '';
    date = new Date(date.replace(/-/g, '/'));
    let map = {
        "M": date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1, //月份
        "d": date.getDate() < 10 ? "0" + date.getDate() : date.getDate(), //日
        "h": date.getHours() < 10 ? "0" + date.getHours() : date.getHours(), //小时
        "m": date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes(), //分
        "s": date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds(), //秒
        "q": Math.floor((date.getMonth() + 3) / 3), //季度
        "S": date.getMilliseconds() //毫秒
    };
    format = format.replace(/([yMdhmsqS])+/g, function (all, t) {
        let v = map[t];
        if (v !== undefined) {
            if (all.length > 1) {
                v = '0' + v;
                v = v.substr(v.length - 2);
            }
            return v;
        } else if (t === 'y') {
            return (date.getFullYear() + '').substr(4 - all.length);
        }
        return all;
    });
    return format.toString();
};

export default formatDate;
