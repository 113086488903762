import {env,storage} from '@uni/apis';
let windows = {};
let document = {};
if (env.isNode) {
    windows = {
        location:{},
        document:{},
        localStorage:{},
        console:console
    };
    document = windows.document;
}else if(env.isWeChatMiniProgram){
    windows = {
        location:{},
        document:{},
        localStorage:{
            setItem:(key,value)=>storage.setStorageSync({
                key: key,
                data: {
                    value: value
                }
            }),
            getItem:(key)=>storage.getStorageSync({
                key: key
            }),
            removeItem:(key)=>storage.removeStorageSync({
                key: key
            })
        },
        sessionStorage:{
            setItem:(key,value)=>storage.setStorageSync({
                key: key,
                data: {
                    value: value
                }
            }),
            getItem:(key)=>storage.getStorageSync({
                key: key
            }),
            removeItem:(key)=>storage.removeStorageSync({
                key: key
            })
        },
        console:console
    };
    document = windows.document;
}else {
    windows = window;
    document = windows.document;
}
export {windows};
export {document};



